import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { Form, Input, Button, Row, Col, Divider, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import SEO from '../blocks/layout/seo';
import { randomString } from '../config/custom/functions';
import LoadingBar from '../blocks/codes/loadingBar';
import LayoutHeader from '../blocks/codes/authPageHeader';
import { Firebase } from '../config/firebase';
import '../styles/auth-page.scss';

export default function Setup() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [validateStatus, setValidateStatus] = useState(true);
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
    async function fetchSettings(user) {
      const response = await axios.get(`${process.env.GATSBY_BASE_URL}/user/${user.uid}`);
      const resData = response.data;
      if (resData.notFound === undefined) {
        navigate('/dashboard');
      } else {
        setLoadingPage(false);
      }
    }

    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchSettings(user);
      }
    });
  }, []);

  const setupAccount = (values) => {
    setLoading(true);
    const { cloudName } = values;
    const newsletterType = ['API Update', 'Feature Update', 'Service News'];
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userObj = {
          cloud_name: cloudName.toLowerCase(),
          uid: user.uid,
          email: user.email,
          newsletter_type: newsletterType,
          created_date: new Date(),
          api_key: randomString(17, 'aA'),
        };
        axios
          .post(`${process.env.GATSBY_BASE_URL}/user`, userObj, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            // console.log(res)
            message.success('🎉 Congratulations! Welcome to NoCodeAPI.');
            if (user.email !== undefined) {
              axios({
                method: 'post',
                url: `${process.env.GATSBY_BASE_URL}/betauser/mailgun/sohoNeGGhPLNdvJO/send`,
                params: {
                  senderName: 'Mohd Danish',
                  senderEmail: 'dan@nocodeapi.com',
                  replyTo: 'hello@nocodeapi.com',
                  to: user.email,
                  subject: 'Welcome to NoCodeAPI! 👋',
                  text: 'I am Mohd Danish & Maker of NoCodeAPI.',
                  template: 'welcome_email',
                },
              })
                .then(function (response) {
                  navigate('/dashboard');
                })
                .catch(function (error) {
                  navigate('/dashboard');
                });
            } else {
              navigate('/dashboard');
            }
          })
          .catch((error) => {
            setLoading(false);
            message.info('This username is not available. Choose a different one.');
          });
      } else {
        navigate('/login');
      }
    });
  };

  return (
    <>
      <SEO title='Setup your account | NoCodeAPI' />

      {!loadingPage ? (
        <div className='auth-page'>
          <LayoutHeader type='setup' />
          <div className='auth-form'>
            <div className='heading'>
              <h2>Final step</h2>
              <p>
                Setup your unique username.{' '}
                <b>
                  <u>maximum 20 characters</u>
                </b>
                ,{' '}
                <b>
                  <u>letters</u>
                </b>{' '}
                and{' '}
                <b>
                  <u>numbers</u>
                </b>{' '}
                only, (NO punctuation or special characters)
              </p>

              <Form
                form={form}
                name='normal_login'
                className='auth-input-form'
                initialValues={{
                  remember: true,
                }}
                onFinish={setupAccount}
              >
                <Form.Item
                  name='cloudName'
                  validateStatus={validateStatus}
                  rules={[
                    {
                      required: true,
                      max: 20,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (/^[A-Za-z0-9_]+$/.test(value)) {
                          setValidateStatus('');
                          return Promise.resolve();
                        }
                        setValidateStatus('error');
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder='Choose a username'
                    pattern='[A-Za-z0-9_]+'
                    minLength='3'
                    size='large'
                    required
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      htmlType='submit'
                      icon={<UserAddOutlined />}
                      loading={loading}
                      className='auth-btn auth-form-button'
                    >
                      Setup Account
                    </Button>
                  )}
                </Form.Item>
              </Form>
              <p>Your username will be used in API endpoints.</p>
            </div>
          </div>
        </div>
      ) : (
        <LoadingBar />
      )}
    </>
  );
}
